import "@fontsource/dm-sans"
import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import blogTheme from "./src/getTheme"; // Your custom MUI theme

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={blogTheme}>{element}</ThemeProvider>
);